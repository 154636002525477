.root {
  display: inline-flex;
  align-items: center;
  border-radius: 12px;
  background: var(--grey-6);
  padding: 7px 10px;
  line-height: 1;
  gap: 11px;
}

.arrow {
  flex-shrink: 0;
  outline: none;
  color: var(--primary-color);
  cursor: pointer;
}

.text {
  flex-grow: 1;
  width: 78px;
  font-weight: 400;
  font-size: 16px;
  color: var(--color-black-primary);
  text-align: center;
}

@media (max-width: 575px) {
  .root {
    padding: 12px;
  }
}