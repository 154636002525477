.root {
  margin-bottom: 150px;
}

.title {
  margin-bottom: 48px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 28px;
  line-height: 1.28;
}

.content {
  display: flex;
  gap: 32px;
}

.left {
  margin-bottom: 100px;
  width: calc(58% - 16px);
}

.right {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  width: calc(42% - 16px);
  padding: 28px 30px 12px;
  border-radius: 16px;
  background: var(--color-white-primary);
}

.rightTitle {
  font-family: var(--headings-font);
  margin-bottom: 60px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.32;
  color: var(--primary-color);
}

.switch {
  margin: 0 10px;
}

.rightRow {
  max-width: 400px;
  margin-bottom: 28px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
}

.rightButtons {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.rightButton {
  padding-left: 40px;
  padding-right: 40px;
}

.rightFooter {
  text-align: right;
  margin-top: 26px;
}

.link {
  color: var(--grey-2);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-decoration-line: underline;
  cursor: pointer;
  outline: none;
}

.leftTitle {
  margin-bottom: 15px;
  color: var(--color-black-primary);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
}

.leftDescription {
  margin-bottom: 15px;
}

.leftRow {
  display: flex;
  align-items: center;
  gap: 20px;
}

.leftRow:not(:last-child) {
  margin-bottom: 87px;
}

.leftButton {
  min-width: 182px;
}

.modalTitle {
  min-height: 30px;
  font-family: var(--headings-font);
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 140px;
  }

  .left {
    margin-bottom: 0;
    width: calc(51% - 16px);
  }

  .right {
    margin-bottom: 0;
    width: calc(49% - 16px);
  }

  .leftRow.leftRow {
    margin-bottom: 67px;
  }
}

@media (max-width: 1024px) and (min-width: 801px) {
  .content {
    flex-direction: column;
    gap: 0;
  }

  .left,
  .right {
    width: 100%;
  }

  .leftRow.leftRow {
    margin-bottom: 36px;
  }

  .right {
    flex-wrap: wrap;
    max-height: 350px;
    gap: 0 38px;
  }

  .rightTitle,
  .rightButtons,
  .rightRows {
    width: calc(50% - 18px);
  }

  .rightRows {
    height: 100%;
    order: 1;
  }

  .rightRow {
    margin-bottom: 16px;
  }

  .rightTitle {
    margin-bottom: 26px;
  }

  .rightButtons {
    justify-content: flex-start;
  }

  .rightFooter {
    margin-top: 10px;
    order: 2;
  }

  .switch {
    margin-left: 0;
    line-height: 2;
  }
}

@media (max-width: 800px) {
  .root {
    margin-bottom: 134px;
  }

  .content {
    flex-direction: column;
    gap: 0;
  }

  .left,
  .right {
    width: 100%;
  }

  .leftRow.leftRow {
    margin-bottom: 35px;
  }

  .rightTitle {
    margin-bottom: 22px;
  }

  .rightRow {
    margin-bottom: 24px;
  }

  .rightFooter {
    margin-top: 7px;
  }

  .switch {
    margin-left: 0;
    line-height: 2;
  }
}

@media (max-width: 575px) {
  .root {
    padding-bottom: 20px;
    margin-bottom: 90px;
  }

  .title {
    margin-bottom: 60px;
    font-size: 36px;
  }

  .leftRow {
    flex-direction: column;
  }

  .leftRow button {
    width: 100%;
  }

  .switch {
    display: block;
    margin: 12px 0 0;
  }

  .switch > div {
    width: 100%;
  }

  .rightButtons {
    margin-top: 20px;
  }

  .rightFooter {
    text-align: center;
  }

  .modalTitle {
    margin-bottom: 15px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.42;
  }
}
