.root {
  margin-bottom: 100px;
}

.title {
  margin-left: 5px;
  margin-bottom: 38px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 28px;
  line-height: 1.28;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 28px 36px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 28px 36px;
}

.block {
  flex-grow: 1;
  width: 100%;
  padding: 14px 0;
  border-radius: var(--border-radius-l);
  box-shadow: var(--box-shadow-f);
  background-color: var(--color-white-primary);
  background-position: right bottom;
  background-repeat: no-repeat;
}

.blockButton {
  flex-grow: 0;
}

.blockTitle {
  max-width: 330px;
  padding: 10px 31px;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
}

.blockDescr {
  max-width: 370px;
  padding: 10px 31px;
  color: var(--grey-0);
}

.blockList {
  margin: 0;
  padding-inline-start: 24px;
}

.blockList :global(.grey) {
  color: var(--grey-3);
}

.blockList :global(.green) {
  color: var(--color-green-primary);
}

.blockButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 11px;
  gap: 20px;
}

.blockButtonsIcon {
  flex-shrink: 0;
}

.item:nth-child(1) {
  width: calc(39% - 18px);
  min-height: 275px;
}
.item:nth-child(1) .block {
  background-position: right -61px bottom -16px;
}
.item:nth-child(1) .blockDescr {
  max-width: 275px;
}

.item:nth-child(2) {
  width: calc(61% - 18px);
  min-height: 275px;
}
.item:nth-child(2) .block {
  background-position: right -78px bottom -122px;
}

.item:nth-child(3) {
  width: calc(57% - 18px);
  min-height: 329px;
}
.item:nth-child(3) .block {
  background-position: right 26px bottom -71px;
}
.item:nth-child(3) .blockDescr {
  max-width: 320px;
}


.item:nth-child(4) {
  width: calc(43% - 18px);
}
.item:nth-child(4) .blockTitle {
  max-width: 250px;
}
.item:nth-child(4) .blockDescr {
  max-width: 300px;
}
.item:nth-child(4) .block {
  background-position: right -97px bottom 29px;
}


.item:nth-child(5) {
  width: calc(49% - 18px);
  min-height: 257px;
}
.item:nth-child(5) .block {
  background-position: right 19px bottom -68px;
}
.item:nth-child(5) .blockDescr {
  max-width: 355px;
}


.item:nth-child(6) {
  width: calc(51% - 18px);
  min-height: 257px;
}
.item:nth-child(6) .block {
  background-size: 470px;
  background-position: right -20px bottom -25px;
}
.item:nth-child(6) .blockDescr {
  max-width: 420px;
}


.item:nth-child(7) {
  width: calc(64% - 18px);
}
.item:nth-child(7) .block {
  background-size: 363px;
  background-position: right 30px center;
}
.item:nth-child(7) .blockTitle {
  max-width: 365px;
}


.item:nth-child(8) {
  width: calc(36% - 18px);
}
.item:nth-child(8) .block {
  background-size: 470px;
  background-position: right -20px bottom -25px;
}
.item:nth-child(8) .blockDescr {
  max-width: 420px;
}


@media (max-width: 1280px) {
  .root {
    margin-bottom: 160px;
  }

  .grid {
    gap: 28px;
  }

  .item {
    gap: 28px;
  }

  .item:nth-child(1) {
    width: calc(41.5% - 14px);
  }
  .item:nth-child(1) .block {
    background-position: right -64px bottom -96px;
    background-size: 240px;
  }

  .item:nth-child(2) {
    width: calc(58.5% - 14px);
  }
  .item:nth-child(2) .block {
    background-position: right -106px bottom -33px;
    background-size: 380px;
  }

  .item:nth-child(3) {
    width: calc(58.5% - 14px);
    min-height: 279px;
  }
  .item:nth-child(3) .block {
    background-position: right 28px bottom -49px;
    background-size: 206px;
  }

  .item:nth-child(4) {
    width: calc(41.5% - 14px);
  }
  .item:nth-child(4) .block {
    background-position: right -136px bottom 25px;
    background-size: 242px;
  }

  .item:nth-child(5) {
    width: calc(55.5% - 14px);
  }
  .item:nth-child(5) .block {
    background-position: right -2px bottom -70px;
  }
  .item:nth-child(5) .blockButton {
    background-position: right 26px bottom -50px;
    padding: 0;
  }

  .item:nth-child(6) {
    width: calc(44.5% - 14px);
    min-height: 369px;
  }
  .item:nth-child(6) .block {
    background-position: right -29px bottom 14px;
    background-size: 478px;
  }
}

@media (max-width: 1024px) {
  .root {
    margin-bottom: 170px;
  }

  .grid {
    gap: 22px;
  }

  .item {
    gap: 22px;
  }

  .item:nth-child(1) {
    width: calc(45.8% - 11px);
  }
  .item:nth-child(1) .block {
    background-position: right 19px bottom -66px;
    background-size: 240px;
  }

  .item:nth-child(2) {
    width: calc(54.2% - 11px);
    min-height: 382px;
  }
  .item:nth-child(2) .block {
    background-position: right -85px bottom -39px;
    background-size: 336px;
  }

  .item:nth-child(3) {
    width: calc(54.1% - 11px);
    min-height: 382px;
  }
  .item:nth-child(3) .block {
    background-position: right 29px bottom -39px;
    background-size: 184px;
  }

  .item:nth-child(4) {
    width: calc(45.9% - 11px);
  }
  .item:nth-child(4) .block {
    background-position: right -55px bottom 6px;
    background-size: 170px;
  }

  .item:nth-child(5) {
    width: calc(61.1% - 11px);
  }
  .item:nth-child(5) .block {
    background-position: right 9px bottom -38px;
    background-size: 130px;
  }
  .item:nth-child(5) .blockButton {
    background-position: right 19px bottom -26px;
    background-size: 103px;
    padding: 0;
  }

  .item:nth-child(6) {
    width: calc(38.9% - 11px);
    min-height: 412px;
  }
  .item:nth-child(6) .block {
    background-position: right 19px bottom 41px;
    background-size: 252px;
  }
}

@media (max-width: 800px) {
  .root {
    margin-bottom: 160px;
  }

  .grid {
    gap: 10px;
  }

  .item {
    gap: 10px;
  }

  .blockTitle,
  .blockDescr {
    padding-left: 14px;
    padding-right: 14px;
  }

  .blockButtons {
    padding: 0;
  }

  .blockButtons > * {
    width: 100%;
  }

  .item.item {
    width: calc(50% - 5px);
  }
  .item:nth-child(1) .block {
    background-position: right -4px bottom -58px;
    background-size: 240px;
  }

  .item:nth-child(2) {
    min-height: 425px;
  }
  .item:nth-child(2) .block {
    background-position: right -69px bottom -71px;
    background-size: 336px;
  }

  .item:nth-child(3) {
    min-height: 425px;
  }
  .item:nth-child(3) .block {
    background-position: right 47px bottom -45px;
    background-size: 184px;
  }

  .item:nth-child(4) .block {
    background-position: right -88px bottom -25px;
    background-size: 254px;
  }

  .item:nth-child(5) .block {
    background-position: right 9px bottom -38px;
    background-size: 130px;
  }
  .item:nth-child(5) .blockButton {
    background-size: 0;
    padding: 0;
  }

  .item:nth-child(6) {
    min-height: 452px;
  }
  .item:nth-child(6) .block {
    background-position: right -7px bottom 43px;
    background-size: 316px;
  }

  .item:nth-child(7) {
    min-height: 300px;
  }
  .item:nth-child(7) .block {
    background-position: center bottom 20px;
    background-size: 240px;
  }

  .blockButtons {
    flex-direction: column;
    padding: 10px;
  }

  .blockButtonsIcon {
    width: 60px;
  }
}

@media (max-width: 575px) {
  .root {
    margin-bottom: 74px;
  }

  .title {
    margin-left: 0;
    margin-bottom: 14px;
    font-size: 36px;
  }

  .item.item {
    height: 450px;
    min-height: 450px;
    width: calc(50% - 5px);
  }

  .block {
    height: 100%;
  }

  .slider {
    overflow: hidden;
    margin-left: calc(var(--container-padding)*-1);
    margin-right: calc(var(--container-padding)*-1);
    padding-left: 15px;
    padding-right: 15px;
  }

  .slider :global(.slick-list) {
    width: 93%;
    margin: 0 -8px;
    overflow: visible;
  }

  .slider :global(.slick-slide) {
    padding: 0 8px;
  }

  .slider :global(.slick-slide):nth-child(1) .block {
    background-position: right -29px bottom -19px;
    background-size: 240px;
  }
  .slider :global(.slick-slide):nth-child(2) .block {
    background-position: right -95px bottom -92px;
    background-size: 390px;
  }
  .slider :global(.slick-slide):nth-child(3) .block {
    background-position: right 64px bottom -28px;
    background-size: 184px;
  }
  .slider :global(.slick-slide):nth-child(4) .block {
    background-position: right -16px bottom -32px;
    background-size: 254px;
  }
  .slider :global(.slick-slide):nth-child(5) .block {
    background-position: right 69px bottom -57px;
    background-size: 176px;
  }
  .slider :global(.slick-slide):nth-child(6) .block {
    background-position: right 8px bottom 38px;
    background-size: 316px;
  }
  .slider :global(.slick-slide):nth-child(7) .block {
    background-position: center center;
    background-size: 316px;
  }
}