.root {
  display: flex;
  align-items: center;
  margin: 90px 0 200px;
}

.left {
  position: relative;
  width: 47%;
  z-index: 1;
}

.right {
  position: relative;
  margin-right: -87px;
  margin-left: -20px;
}

.image {
  width: 100%;
  height: 100%;
}

.title {
  margin: 0;
  font-size: 40px;
  line-height: 1.25;
  font-weight: 700;
  font-family: var(--headings-font);
}

.buttons {
  display: flex;
  margin-top: 30px;
  gap: 22px;
}

.button {
  flex-direction: row-reverse;
  gap: 10px;
}

.button.button > span {
  margin: 0;
}

.description {
  margin-top: 30px;
  font-size: 19px;
  color: var(--grey-2);
}

.play {
  position: absolute;
  top: 40%;
  left: 20px;
  width: 102px;
  height: 102px;
  border: none;
  box-shadow: none;
  background: none;
  padding: 0;
  border-radius: 100px;
  line-height: 0;
  transition: box-shadow var(--transition);
}

.playIcon,
.playIcon svg {
  width: 100%;
  height: 100%;
}

.play:hover {
  box-shadow: var(--box-shadow-e);
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 162px;
  }

  .left {
    width: 515px;
    flex-shrink: 0;
  }

  .right {
    margin-left: -40px;
    margin-right: -67px;
  }

  .buttons {
    flex-direction: column;
    max-width: 350px;
    gap: 12px;
  }

  .buttons > * {
    width: 100%;
  }

  .description {
    max-width: 450px;
  }

  .play {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 1024px) {
  .root {
    margin-top: 130px;
    margin-bottom: 174px;
    align-items: flex-start;
  }

  .right {
    margin-top: 50px;
    margin-left: -145px;
    margin-right: -77px;
  }

  .description {
    max-width: 380px;
  }

  .play {
    width: 60px;
    height: 60px;
  }

  .video {
    height: 382px;
  }
}

@media (max-width: 800px) {
  .root {
    margin-top: 45px;
    margin-bottom: 110px;
  }

  .buttons {
    max-width: 222px;
    margin-top: 90px;
  }

  .buttons > * {
    padding: 13px;
    font-size: 14px;
  }

  .right {
    margin-top: 152px;
    margin-left: -280px;
    margin-right: -48px;
  }

  .description {
    max-width: 260px;
    font-size: 16px;
  }

  .play {
    width: 56px;
    height: 56px;
  }

  .video {
    height: 292px;
  }
}

@media (max-width: 575px) {
  .root {
    flex-direction: column;
    margin-top: 32px;
  }

  .left {
    width: 100%;
  }

  .right {
    margin: 7px -15px 0;
    padding: 0;
  }

  .title {
    font-size: 32px;
  }

  .buttons {
    flex-direction: column-reverse;
    width: 100%;
    max-width: 100%;
    margin-top: 55px;
  }

  .description {
    display: none;
  }

  .video {
    height: 240px;
  }
}
