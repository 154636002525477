.root {
  margin-bottom: 270px;
}

.label {
  margin-bottom: 6px;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.title {
  margin-bottom: 100px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 28px;
  line-height: 1.28;
  text-align: center;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.item {
  border-radius: var(--border-radius-xl);
  border: 1px solid var(--primary-color);
}

.item.open {
  background: var(--color-white-primary);
  border-color: transparent;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px 30px 35px;
  font-size: 20px;
  line-height: 1.4;
  cursor: pointer;
}

.arrow {
  line-height: 0;
  transition: all var(--transition);
}

.content {
  padding: 0 90px 0 35px;
  max-height: 0;
  overflow: hidden;
  font-size: 18px;
  line-height: 1.4;
  color: var(--grey-1);
  transition: all var(--transition);
}

.content a {
  color: var(--primary-color);
  font-weight: 600;
}

.open .header {
  font-weight: 600;
}

.open .arrow {
  transform: scaleY(-1);
}

.open .content {
  max-height: 500px;
  padding-bottom: 30px;
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 220px;
  }

  .header {
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 16px;
  }

  .content {
    font-size: 16px;
  }

  .open .content {
    padding-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .root {
    margin-bottom: 190px;
  }
}

@media (max-width: 575px) {
  .root {
    margin-bottom: 180px;
  }

  .label {
    font-size: 16px;
  }

  .title {
    margin-bottom: 48px;
    font-size: 25px;
  }

  .header {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}