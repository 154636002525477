.root {
  margin-bottom: 190px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 28px 52px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 28px 52px;
}

.block {
  flex-grow: 1;
  width: 100%;
  padding: 10px 0;
  border-radius: var(--border-radius-l);
  box-shadow: var(--box-shadow-f);
  background-color: var(--color-white-primary);
  background-position: right bottom;
  background-repeat: no-repeat;
}

.blockButton {
  flex-grow: 0;
  padding: 0;
  text-align: right;
}

.blockTitle {
  max-width: 510px;
  padding: 27px 40px 12px;
  font-family: var(--headings-font);
  font-size: 28px;
  line-height: 1.32;
  font-weight: 700;
  color: var(--primary-color);
}

.blockDescr {
  max-width: 400px;
  padding: 12px 40px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.4;
}

.blockButtons {
  padding: 11px;
}

.item:nth-child(1) {
  width: calc(55% - 26px);
  min-height: 497px;
}
.item:nth-child(1) .block {
  background-position: right -3px bottom -175px;
}

.item:nth-child(2) {
  width: calc(45% - 26px);
}
.item:nth-child(2) .block {
  background-position: right 34px bottom 46px;
  background-size: 200px;
}
.item:nth-child(2) .blockButton {
  background-position: left 44px bottom -30px;
  background-size: 118px;
}
.item:nth-child(2) .blockDescr {
  max-width: 320px;
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 140px;
  }

  .grid {
    gap: 28px;
  }

  .item {
    gap: 26px;
  }

  .blockTitle {
    padding: 10px 30px;
  }

  .blockDescr {
    padding: 10px 30px;
    font-size: 16px;
  }

  .item:nth-child(1) {
    min-height: 415px;
    width: calc(53% - 14px);
  }
  .item:nth-child(1) .block {
    background-position: right 35px bottom -127px;
    background-size: 268px;
  }

  .item:nth-child(2) {
    width: calc(47% - 14px);
  }
  .item:nth-child(2) .block {
    background-position: right 23px bottom 18px;
    background-size: 190px;
  }
  .item:nth-child(2) .blockButton {
    background-position: left 25px bottom -14px;
    background-size: 92px;
  }
}

@media (max-width: 1024px) {
  .grid {
    gap: 22px;
  }

  .item {
    gap: 22px;
  }

  .item:nth-child(1) {
    min-height: 451px;
    width: calc(57% - 11px);
  }
  .item:nth-child(1) .block {
    background-position: right 35px bottom -110px;
    background-size: 222px;
  }

  .item:nth-child(2) {
    width: calc(43% - 11px);
  }
  .item:nth-child(2) .block {
    background-position: right 12px bottom 14px;
    background-size: 148px;
  }
  .item:nth-child(2) .blockButton {
    background-size: 0;
  }

  .blockButtons > * {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .root {
    margin-bottom: 134px;
  }

  .grid {
    gap: 17px;
  }

  .item {
    gap: 17px;
    align-items: center;
  }

  .blockTitle {
    max-width: 393px;
  }

  .blockDescr {
    max-width: 341px;
  }

  .item:nth-child(1) {
    min-height: 305px;
    width: 100%;
  }
  .item:nth-child(1) .block {
    background-position: right 29px bottom -97px;
    background-size: 222px;
  }

  .item:nth-child(2) {
    width: 100%;
  }
  .item:nth-child(2) .block {
    min-height: 219px;
    background-position: right 89px bottom 38px;
    background-size: 148px;
  }
  .item:nth-child(2) .blockTitle {
    max-width: 247px;
  }
  .item:nth-child(2) .blockDescr {
    max-width: 264px;
  }
  .item:nth-child(2) .blockButton {
    min-height: 0;
    background-size: 0;
    max-width: 276px;
  }

  .blockButtons {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .root {
    margin-bottom: 90px;
  }

  .grid {
    gap: 10px;
  }

  .item:nth-child(1) {
    min-height: 466px;
  }
  .item:nth-child(1) .block {
    background-position: right 64px bottom -112px;
  }

  .item:nth-child(2) {
    min-height: 418px;
  }
  .item:nth-child(2) .block {
    background-position: right 89px bottom 25px;
    background-size: 178px;
  }
  .item:nth-child(2) .blockButton {
    display: none;
  }
}