.root {
  display: flex;
  margin-bottom: 80px;
}

.content {
  display: flex;
  flex-grow: 1;
  min-width: 0;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-right: 10px;
  min-width: 0;
  flex-grow: 1;
}

.sliderWrapper {
  position: relative;
  flex-grow: 1;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.slider :global(.slick-list) {
  border-radius: var(--border-radius-l);
}

.slider :global(.slick-slide) > div {
  height: 100%;
}

.dots {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
  gap: 10px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: var(--border-radius-xl);
  background: var(--primary-color);
}

.dot.active {
  width: 14px;
  height: 14px;
  margin: 0 -3px;
}

.title {
  margin: 10px 0 27px;
  font-size: 20px;
  font-weight: 700;
  font-family: var(--headings-font);
}

.nav {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 230px;
  margin-left: 10px;
  gap: 15px;
}

.navItem {
  display: flex;
  align-items: center;
  padding: 10px 3px;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-m);
  transition: all var(--transition);
  cursor: pointer;
}

.navItem:hover,
.navItem.active {
  background: var(--color-white-primary);
  box-shadow: var(--box-shadow-a);
  border-color: transparent;
  color: var(--primary-color);
}

.navItemIcon {
  flex-shrink: 0;
  width: 78px;
  height: 78px;
}

.navItemText {
  padding-right: 10px;
  flex-grow: 1;
  text-align: center;
  font-weight: 600;
  line-height: 1.2;
}

.videoWrapper {
  position: relative;
  height: 100%;
}

.videoWrapper::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-l);
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius-l);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.loaderIcon {
  display: inline-block;
  line-height: 0;
  color: var(--primary-color);
  animation: loading-circle 0.75s steps(12,end) infinite;
  animation-duration: 0.75s;
}

.loaderIcon svg {
  height: 40px;
  width: 40px;
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 150px;
  }

  .title {
    margin-top: 2px;
    margin-bottom: 13px;
  }

  .nav {
    width: 202px;
    margin-left: 20px;
  }

  .navItem {
    padding: 4px 2px;
  }

  .navItemIcon {
    width: 63px;
    height: 65px;
  }

  .navItemText {
    font-size: 15px;
  }
}

@media (max-width: 1024px) {
  .root {
    flex-direction: column;
    margin-bottom: 94px;
  }

  .content {
    flex-direction: column;
  }

  .contentContainer {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .title {
    margin-top: 0;
  }

  .dots {
    margin: 10px 0;
    flex-direction: row;
  }

  .dot.active {
    margin: -3px 0;
  }

  .nav {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;
    flex-direction: row;
  }

  .navItem {
    padding: 10px 5px;
    flex: 1;
  }

  .navItemIcon {
    display: none;
  }

  .navItemText {
    padding: 0;
  }

  .slider {
    position: static;
  }

  .video {
    object-fit: contain;
  }
}

@media (max-width: 800px) {
  .root {
    margin-bottom: 90px;
  }

  .title {
    margin: 0 auto 17px;
    max-width: 465px;
    font-size: 20px;
    line-height: 1.25;
    text-align: center;
  }

  .nav {
    flex-wrap: wrap;
    justify-content: center;
    gap: 9px 8px;
  }

  .navItem {
    flex: 0 0;
    flex-basis: 156px;
  }
}

@keyframes loading-circle {
  100% {
    transform: rotate(360deg);
  }
}
