.root {
  margin-bottom: 200px;
}

.content {
  display: flex;
  justify-content: center;
  gap: 100px;
}

.item {
  width: 260px;
}

.title {
  margin-bottom: 5px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 28px;
  line-height: 1.28;
  color: var(--primary-color);
}

.description {
  font-weight: 500;
}

.buttons {
  text-align: center;
  margin-top: 67px;
}

.button {
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 170px;
  }
}

@media (max-width: 1024px) {
  .root {
    margin-bottom: 145px;
  }

  .content {
    gap: 35px;
  }
}

@media (max-width: 800px) {
  .root {
    margin-bottom: 120px;
  }

  .content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 32px;
  }

  .buttons {
    margin-top: 56px;
  }
}

@media (max-width: 800px) {
  .root {
    margin-bottom: 90px;
  }

  .content {
    gap: 10px;
  }

  .item {
    padding: 17px 25px;
    background: var(--color-white-primary);
    box-shadow: var(--box-shadow-c);
    border-radius: var(--border-radius-l);
  }

  .title {
    margin-bottom: 0;
  }

  .buttons {
    display: none;
  }
}
