.root {
  margin-bottom: 160px;
}

.title {
  margin-bottom: 4px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 28px;
  line-height: 1.28;
}

.subtitle {
  font-size: 20px;
  font-weight: 400;
  color: var(--grey-0);
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 48px 33px;
}

.itemTitle {
  width: calc(66.66% - 12px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item {
  width: calc(33.33% - 22px);
}

.block {
  flex-grow: 1;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 28px 31px;
  border-radius: var(--border-radius-l);
  box-shadow: var(--box-shadow-f);
  background-color: var(--color-white-primary);
}

.blockButton {
  flex-grow: 0;
}

.blockTitle {
  position: relative;
  margin-bottom: 18px;
  font-size: 20px;
  line-height: 1.4;
  font-weight: 600;
  color: var(--grey-0);
  z-index: 1;
}

.blockDescr {
  position: relative;
  max-width: 230px;
  color: var(--grey-0);
  z-index: 1;
}

.blockList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.blockListItem:not(:last-child) {
  margin-bottom: 12px;
}

.image {
  position: absolute;
  right: 14px;
  bottom: 14px;
  background-color: var(--color-white-primary);
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 140px;
  }

  .grid {
    gap: 24px 18px;
  }

  .item {
    width: calc(33.33% - 12px);
  }

  .itemTitle {
    width: calc(66.66% - 6px);
  }

  .title {
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 16px;
  }

  .block {
    padding: 19px 17px 48px;
  }

  .blockTitle {
    font-size: 16px;
  }

  .blockDescr {
    font-size: 14px;
  }

  .blockListItem:not(:last-child) {
    margin-bottom: 5px;
  }

  .image {
    right: 18px;
    bottom: 24px;
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 1024px) {
  .root {
    margin-bottom: 70px;
  }

  .grid {
    gap: 20px 16px;
  }

  .item {
    width: calc(33.33% - 11px);
  }

  .itemTitle {
    width: calc(66.66% - 6px);
  }

  .subtitle {
    max-width: 351px;
  }

  .block {
    min-height: 273px;
  }

  .image {
    right: 50%;
    bottom: 12px;
    transform: translateX(50%);
  }
}

@media (max-width: 800px) {
  .root {
    margin-bottom: 90px;
  }

  .grid {
    gap: 18px 10px;
  }

  .item {
    width: calc(50% - 5px);
  }

  .itemTitle {
    width: 100%;
  }

  .subtitle {
    margin-bottom: 14px;
  }

  .blockTitle {
    max-width: 176px;
  }
}

@media (max-width: 575px) {
  .root {
    margin-bottom: 90px;
  }

  .title {
    margin-bottom: 10px;
    font-size: 36px;
  }

  .subtitle {
    margin-bottom: 32px;
  }

  .block {
    padding: 20px 22px;
  }

  .blockTitle {
    margin-bottom: 16px;
  }

  .slider {
    overflow: hidden;
    margin-left: calc(var(--container-padding)*-1);
    margin-right: calc(var(--container-padding)*-1);
    padding-left: 15px;
    padding-right: 15px;
  }

  .slider :global(.slick-list) {
    width: 72%;
    margin: 0 -5px;
    overflow: visible;
  }

  .slider :global(.slick-slide) {
    padding: 0 5px;
  }
}
