.root {
  display: flex;
  margin-bottom: 150px;
}

.title {
  margin-left: 5px;
  margin-bottom: 48px;
  font-family: var(--headings-font);
  font-weight: 700;
  font-size: 28px;
  line-height: 1.28;
}

.left {
  flex-shrink: 0;
  width: 53.4%;
  margin-right: 72px;
}

.right {
  position: relative;
}

.robot {
  position: absolute;
  top: 0;
  right: 154px;
  opacity: 0;
  transform: translateY(-200px);
  transition: all var(--transition-slow);
}

.blocks {
  display: flex;
  flex-direction: column;
  gap: 34px;
}

.block {
  height: 426px;
  border-radius: var(--border-radius-l);
  background-color: var(--color-white-primary);
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: var(--box-shadow-f);
}

.items {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 84px;
  gap: 34px;
  z-index: 2;
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 426px;
}

.itemNumber {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 74px;
  height: 74px;
  font-size: 46px;
  font-weight: 700;
  background: var(--primary-color);
  color: var(--color-white-primary);
  border-radius: 1000px;
  text-align: center;
}

.itemContent {
  margin-top: 26px;
  padding: 10px 30px 20px;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-m);
  background: var(--body-bg);
}

.itemTitle {
  margin-bottom: 10px;
  font-family: var(--headings-font);
  font-size: 28px;
  line-height: 1.28;
  font-weight: 700;
  color: var(--primary-color);
}

.itemDescription {
  font-weight: 500;
}

.progress {
  position: absolute;
  top: 0;
  left: 37px;
  width: 10px;
  height: 100%;
  background: var(--grey-3);
  transform: translateX(-50%);
  z-index: -1;
}

.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;
  background: var(--primary-color);
  transition: all var(--transition-slow);
}

.progress::before,
.progress::after {
  content: '';
  position: absolute;
  top: 0;
  left: 5px;
  width: 74px;
  height: 74px;
  transform: translateX(-50%);
  background: var(--body-bg);
  border: 10px solid var(--primary-color);
  border-radius: 1000px;
  box-sizing: border-box;
  z-index: 1;
}

.progress::after {
  top: auto;
  bottom: 0;
}

.item:nth-child(1) {
  max-width: 402px;
}

.item:nth-child(2) {
  max-width: 225px;
}

.item:nth-child(4) {
  max-width: 383px;
}

@media (max-width: 1280px) {
  .root {
    margin-bottom: 180px;
  }

  .left {
    width: 52%;
    margin-right: 46px;
  }

  .title {
    margin-bottom: 12px;
  }

  .blocks,
  .items {
    gap: 80px;
  }

  .block,
  .item {
    height: 324px;
  }

  .robot {
    right: 95px;
    width: 165px;
    transform: translateY(-150px);
  }

  .progress {
    width: 8px;
    left: 31px;
  }

  .progress::before,
  .progress::after {
    width: 62px;
    height: 62px;
    border-width: 8px;
  }

  .item {
    justify-content: flex-end;
  }

  .item:nth-child(2) {
    max-width: 270px;
  }

  .item:nth-child(4) {
    max-width: 400px;
  }

  .itemNumber {
    width: 62px;
    height: 62px;
    font-size: 40px;
  }

  .itemTitle {
    margin-bottom: 8px;
  }

  .itemContent {
    margin-top: 18px;
    padding: 10px 24px 16px;
  }
}

@media (max-width: 1024px) {
  .root {
    margin-bottom: 160px;
  }

  .blocks,
  .items {
    gap: 90px;
  }

  .items {
    padding-top: 100px;
  }

  .left {
    width: 49%;
    margin-right: 40px;
  }

  .title {
    margin-bottom: 28px;
  }

  .block,
  .item {
    height: 240px;
  }

  .block:nth-child(1) {
    background-size: 100%;
  }

  .block:nth-child(2) {
    background-size: 45%;
  }

  .block:nth-child(3) {
    background-size: 85%;
  }

  .block:nth-child(4) {
    background-size: 85%;
  }

  .robot {
    right: 46px;
    transform: translateY(-95px);
  }

  .itemContent {
    margin-top: 8px;
    padding: 14px 24px;
  }

  .item:nth-child(1) {
    max-width: 245px;
  }

  .item:nth-child(2) {
    max-width: 228px;
  }
}

@media (max-width: 800px) {
  .root {
    margin-bottom: 230px;
  }

  .blocks,
  .items {
    gap: 233px;
  }

  .block,
  .item {
    height: 226px;
  }

  .title {
    margin-bottom: 18px;
  }

  .left {
    width: 60%;
    margin-right: 24px;
  }

  .robot {
    right: -160px;
    transform: translateY(-220px);
  }

  .items {
    padding-top: 90px;
  }

  .item {
    position: relative;
  }

  .itemContent {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 14px;
    padding: 7px 24px;
    transform: translateX(-50%);
  }

  .itemTitle {
    margin-bottom: 3px;
  }

  .item:nth-child(1) .itemContent {
    width: 245px;
  }

  .item:nth-child(2) .itemContent {
    width: 228px;
  }

  .item:nth-child(3) .itemContent {
    width: 258px;
  }

  .item:nth-child(4) .itemContent {
    width: 310px;
  }
}

@media (max-width: 575px) {
  .root {
    margin-bottom: 90px;
  }

  .left {
    width: 100%;
    margin: 0;
  }

  .right {
    display: none;
  }

  .title {
    margin: 0 5px 60px 0;
    font-size: 36px;
  }

  .blocks {
    gap: 84px;
  }

  .block.block {
    height: 0;
    padding-bottom: 68%;
  }

  .item.item {
    height: auto;
    max-width: 100%;
  }

  .item.item .itemContent {
    width: 100%;
  }

  .itemContent {
    position: static;
    transform: none;
    margin-top: 10px;
    border-radius: var(--border-radius-l);
  }

  .item:nth-child(1) .block {
    background-size: 74%;
  }

  .item:nth-child(2) .block {
    background-size: 45%;
  }

  .item:nth-child(3) .block {
    background-size: 86%;
  }

  .item:nth-child(4) .block {
    background-size: 85%;
  }

  .itemNumber {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 29px;
    height: 29px;
    font-size: 20px;
  }
}