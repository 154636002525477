.root {
  width: 660px;
  padding-left: 1px;
  padding-right: 1px;
}

.table {
  width: 100%;
}

.table td {
  font-size: 13px;
  line-height: 1.2;
  padding: 2px 0;
}

.table tr:nth-child(2n) td {
  background: var(--grey-7);
}

.tableValue {
  text-align: right;
}


.totalTableWrap {
  margin-top: 13px;
  margin-bottom: 8px;
}

.totalTable {
  width: 100%;
}

.totalTable th {
  font-size: 13px;
  line-height: 1.2;
  padding: 2px 0 5px;
  font-weight: 600;
  text-align: right;
}

.totalTable td {
  font-size: 13px;
  line-height: 1.2;
  padding: 2px 0;
}

.totalTableValue {
  width: 20%;
  text-align: right;
}


.totalRow {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2;
  padding: 2px 0;
  gap: 12px;
}

.totalValue {
  text-align: right;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  gap: 20px;
}

.notice {
  max-width: 340px;
  font-size: 13px;
  line-height: 1.15;
  color: var(--grey-2);
}

.button {
  width: 240px;
}

.totalBlock {
  margin-bottom: 14px;
  padding: 10px;
  border-radius: 10px;
  background: var(--grey-7);
}

.totalBlock:nth-child(2) {
  background: var(--color-green-4);
}

.totalBlock:nth-child(3) {
  background: var(--color-green-3);
}

.totalBlockTitle {
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2;
}

.totalBlockRow {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  line-height: 1.2;
}

.totalBlockRow:not(:last-child) {
  margin-bottom: 8px;
}

@media (max-width: 800px) {
  .notice {
    max-width: 254px;
  }

  .root {
    width: 100%;
    max-width: 528px;
  }
}

@media (max-width: 575px) {
  .totalTableWrap {
    margin-top: 35px;
    margin-bottom: 20px;
  }

  .footer {
    flex-direction: column;
  }

  .notice {
    max-width: 100%;
  }

  .button {
    padding-top: 12px;
    padding-bottom: 12px;
    width: 100%;
    max-width: 280px;
  }
}